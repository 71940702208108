import React from "react";
import { getNairobiTime } from "../Utilities/utilities";
import { pinnisoftUrl } from "../Utilities/globals";

const Mainfooter = () => {
  return (
    <div style={{ paddingLeft: "14px", paddingRight: "14px" }}>
      <div
        className="footer"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "100%",
          backgroundColor: "#fff",
          borderRadius: "4px",
        }}
      >
        <p
          className="my-0"
          style={{ fontSize: "10px", color: "black", textAlign: "center" }}
        >
          Copright &copy; {getNairobiTime().slice(0, 4)} PinniTAGS is a product
          of{" "}
          <a href={pinnisoftUrl} className="link">
            PinniSOFT
          </a>{" "}
          <br />
          All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Mainfooter;
