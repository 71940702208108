import React, { useEffect, useRef, useState } from "react";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import { Button } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Alert, FormCheck } from 'react-bootstrap';
import logo from './images/logo.jpg'
import axios from '../api/axios';
import SwitchSelector from "react-switch-selector";
import TextTyper from 'text-type-animation-effect-react'
import '../app.css'
import './loader.css'
import { Modal } from 'antd';
import Loader from "./Loader";
import ColoredD from './ColorSport';
import useAuth from "../hooks/useAuth";
import Cookies from 'js-cookie';
import { twit } from "./Notificationpopout";
import { validateEmail } from "../Utilities/emailVerification";
import { CiLogin } from "react-icons/ci";
import { IoReloadSharp } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { VscEyeClosed } from "react-icons/vsc";
import { VscEye } from "react-icons/vsc";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { pinnitagsCookiePolicy, pinnitagsOTPpolicy, pinnitagsPrivacyPolicy, pinnitagsTermsConditions } from "../Utilities/globals";
import { getNairobiTime } from "../Utilities/utilities";




const Login = () => {





  const navigate = useNavigate();
  const [contact, setContact] = useState('');
  const [otp, setOtp] = useState('');
  const [otperror, setOtperror] = useState();
  const [contactMethod, setContactMethod] = useState("email");

  const [email, setEmail] = useState('')
  const [step, setStep] = useState(1)
  const [errorMsg, setErrorMsg] = useState()
  const [successMsg, setSuccessMsg] = useState()
  const [loginsuccessMsg, setLoginsuccessMsg] = useState()
  const [otploading, SetOtploading] = useState(false)
  const [otpErrorOpen, setotpErrorOpen] = useState(true)
  const [checked, setChecked] = useState(false);

  const Otppayload = { phone: contact }
  const Emailotppayload = { email: email }
  const Loginpayload = { contact: contact, otp: otp }
  const Emailloginpayload = { email: email, otp: otp }

  const LOGIN_URL = '/api/auth/login';
  const OtpUrl = '/api/auth/otp/'
  const options = [
    {
      label: <p title="Email" style={{margin:'0px'}} ><MdOutlineEmail /></p>,
      value: "email",
      selectedBackgroundColor: "#f3a95c",
    },
    {
      label: <p  title="Mobile" style={{margin:'0px'}} ><MdOutlinePhone /></p>,
      value: "phone",
      selectedBackgroundColor: "#569f51"
    }
  ];

  // const options = [
  //   {
  //     label: 'Email',
  //     value: "email",
  //     selectedBackgroundColor: "#f3a95c",
  //   },
  //   {
  //     label: "Mobile No.",
  //     value: "phone",
  //     selectedBackgroundColor: "#569f51"
  //   }
  // ];

  const initialSelectedIndex = options.findIndex(({ value }) => value === contactMethod);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const inputRef = useRef(null);

  useEffect(() => {

    if (((contactMethod === "phone")&&(contact)) || email) {
      inputRef?.current?.focus();
    }

  }, [step]);





  const otpRequest = async () => {
    if (contactMethod === "phone") {
      if (contact === '' || contact.length < 10) {
        twit('info','Please provide a valid phone number.')
      } else {
        SetOtploading(true)
        try {
          const response = await axios.post(OtpUrl,
            JSON.stringify(Otppayload),
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );

          if (response.data.message.includes("successfully")) {

            SetOtploading(false);
            //setSuccessMsg(response.data.message);


            setSuccessMsg()
            setStep(2)
            twit("success", response.data.message, 5);

          }
        } catch (err) {
          SetOtploading(false);

          if (!err?.response) {

            if (err.response) {

              //setOtperror(err.response.data.message);
              twit("error", err.response.data.message);
            } else {

              //setOtperror("network error");
              twit("error", "Network error.");
            }



          } else if (err.response?.status === 400) {
            //setOtperror(err.response.data.message);
            twit("error", err.response.data.message);
          } else if (err.response?.status === 401) {
            //setOtperror(err.response.data.message);
            twit("warning", err.response.data.message, 3);
          } else {
            //setOtperror('Otp request Failed');
            twit("error", 'Otp request Failed.');
          }
        }

      }


    } else {
      if (email === '' || !validateEmail(email)) {
        //setOtperror("invalid email")
        twit("warning", "Invalid email format.");
      } else {
        SetOtploading(true)
        try {
          const response = await axios.post(OtpUrl,
            JSON.stringify(Emailotppayload),
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );
          if (response.data.message.includes("successfully")) {

            SetOtploading(false);
            //setSuccessMsg(response.data.message);


            setSuccessMsg()
            setStep(2)
            twit("success", response.data.message, 5);

          }
        } catch (err) {
          SetOtploading(false);

          if (!err?.response) {

            if (err.response) {

              //setOtperror(err.response.data.message);
              twit("error", err.response.data.message);
            } else {

              //setOtperror("network error");
              twit("error", "Network error.");
            }



          } else if (err.response?.status === 400) {
            //setOtperror(err.response.data.message);
            twit("error", err.response.data.message);
          } else if (err.response?.status === 401) {
            //setOtperror(err.response.data.message);
            twit("warning", err.response.data.message, 3);
          } else {
            //setOtperror('Otp request Failed');
            twit("error", 'Otp request Failed.');
          }
        }
      }



    }


  }
  const handleSubmit = async (e) => {
    e.preventDefault();




    if (contactMethod === "phone") {

      if (contact === '' || contact.length < 10 || otp.length < 5 || otp.length > 5) {
        if (contact === '' || contact.length < 10) {
          //setErrorMsg('invalid email ')
          twit("info", 'Please provide a valid mobile number.');
        } else {
          //setErrorMsg('invalid otp length')
          twit("info", 'Invalid OTP length.');
        }
      } else {
        SetOtploading(true)
        try {
          const response = await axios.post(LOGIN_URL,
            JSON.stringify(Loginpayload),
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );




          //setAuth({  accessToken });
          if (response.data.message === 'You have logged in successfully') {
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('rid', response.data.rid);


            SetOtploading(false)

            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 1440)

            Cookies.set('loggedin', true, { expires });
            setContact('');
            setOtp('');
            setLoginsuccessMsg("response.data.message")
            navigate('/home');


            if (response.data.LoginMessage) {
              twit("success", "Welcome " + response.data.email + ',' + response.data.LoginMessage, 2.5);
            } else {
              twit("success", "Welcome " + response.data.email, 2.5);
            }

            // if(response.data.message === 'You have logged in successfully'){

            // }else if(response.data.message ==='session changed'){
            //   twit("success", "Welcome " + response.data.email + ', You have been logged out of your previous session.', 3.5);
            // }



          } else {
            SetOtploading(false)

            //setErrorMsg(response.data.message)
            twit("error", response.data.message);
          }


        } catch (err) {
          SetOtploading(false)
          if (!err?.response) {
            //setErrorMsg('No Server Response');
            twit("error", 'No Server Response.');
          } else if (err.response?.status === 400) {
            //setErrorMsg('Missing Username or Password');
            twit("info", 'Missing Username or Password.');
          } else if (err.response?.status === 401) {
            //setErrorMsg(err.response.data.message);
            twit("info", err.response.data.message);
          } else if (err.response?.status === 403) {
            //setErrorMsg(err.response.data.message);
            twit("info", err.response.data.message,3);
          } else {
            //setErrorMsg('Login Failed');
            twit("error", 'Login failed.');
          }

        }
      }


    } else {


      if (!validateEmail(email) || email === '' || otp.length < 5 || otp.length > 5) {
        if (!validateEmail(email) || email === '') {
          //setErrorMsg('invalid email ')
          twit("error", 'Invalid email format. ');
        } else {
          //setErrorMsg('invalid otp length')
          twit("error", 'Invalid OTP length.');
        }

      } else {
        SetOtploading(true)
        try {
          const response = await axios.post(LOGIN_URL,
            JSON.stringify(Emailloginpayload),
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );




          //setAuth({  accessToken });
          if (response.data.message === 'You have logged in successfully') {
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('rid', response.data.rid);


            SetOtploading(false)

            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 1440)

            Cookies.set('loggedin', true, { expires });
            setContact('');
            setOtp('');
            setLoginsuccessMsg("response.data.message")
            navigate('/home');


            if (response.data.LoginMessage) {
              twit("success", "Welcome " + response.data.email + ',' + response.data.LoginMessage, 2.5);
            } else {
              twit("success", "Welcome " + response.data.email, 2.5);
            }

            // if(response.data.message === 'You have logged in successfully'){

            // }else if(response.data.message ==='session changed'){
            //   twit("success", "Welcome " + response.data.email + ', You have been logged out of your previous session.', 3.5);
            // }



          } else {
            SetOtploading(false)

            //setErrorMsg(response.data.message)
            twit("error", response.data.message);
          }


        } catch (err) {
          SetOtploading(false)
          if (!err?.response) {
            //setErrorMsg('No Server Response');
            twit("error", 'No Server Response.');
          } else if (err.response?.status === 400) {
            //setErrorMsg('Missing Username or Password');
            twit("info", 'Missing Username or Password.');
          } else if (err.response?.status === 401) {
            //setErrorMsg(err.response.data.message);
            twit("info", err.response.data.message);
          }else if (err.response?.status === 403) {
            //setErrorMsg(err.response.data.message);
            twit("info", err.response.data.message , 3);
          } else {
            //setErrorMsg('Login Failed');
            twit("error", 'Login failed.');
          }

        }

      }

    }


  }



  // const handleRadioChange = (e) => {
  //     setContactMethod(e.target.value);
  //     if(step===3){
  //       setOtperror();
  //     }
  //   };


  const handleYes = () => {

    setStep(2)

  }

  const handleNo = () => {

    setStep(3)


  }

  const handleOtppop = () => {
    setOtperror()
    setErrorMsg()

  }


  const handleSwitch = (newValue) => {
    setContactMethod(newValue);
    if (step === 3) {
      setOtperror();
    }
    if (step === 2) {
      setErrorMsg()
    }
  };


  const loggedin = Cookies.get('loggedin');


  useEffect(() => {
    const loggedin = Cookies.get('loggedin');
    if (loggedin) {

      navigate("/home");
    }

  }, [])


  if (otploading) {
    return (<Loader />)
  } else {
    return (

      <div className='login-container ' >
        {/* <div className="back-wrapper1"  ></div>
          <div className="back-wrapper2"  ></div>
          <div className="back-wrapper3" ></div> */}





        <Card className='login-card ' >

          <Card.Body className="login-card-body">

            <Card.Img variant="top" src={logo} className='login2-image' />

            {step === 1 && (
              <>
                {screenWidth <= 700 && (
                  <Col className="text-center" >

                    {/* <div className="login2-image">
                    <Card.Img variant="top" src={logo} className="login2-image mb-3" />

                  </div> */}


                    <Row className="home-text">
                      {/* <h6 className="text-success">Hey there !!!</h6> */}

                      <TextTyper text={"Do you have an OTP for today ?"} interval={50} Markup={"p"} />
                    </Row>

                    <Row className="mb-5  d-flex justify-content-center ">
                      {/* <Button variant="light" type="button" className='text-nowrap mb-1 h-11  w-25 yes-button-login' onClick={handleYes}>
                        Yes
                      </Button>
                      <Button variant="light " type="button" className=' text-nowrap h-11 w-25  mb-1 no-button-login' onClick={handleNo} >
                        No
                      </Button> */}

                      <Button onClick={handleYes} title='Already have an OTP.' className='text-nowrap mb-1 h-11  w-25 yes-button-login' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" >
                        <TiTick className='mx-1' style={{ width: '15px', height: '15px' }} /> | Yes
                      </Button>
                      <Button onClick={handleNo} title='Do not have an OTP.' className=' text-nowrap h-11 w-25  mb-1 no-button-login' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" >
                        <ImCross className='mx-1' style={{ width: '9px', height: '9px'}} /> | No
                      </Button>

                    </Row>
                    <div className="mb-5 " style={{ height: '2vh' }}>

                    </div>
                    <p className=' text-muted tcp '>&nbsp;By clicking sign up you agree to our <a href={pinnitagsTermsConditions}  target="_blank" rel="noopener noreferrer" className="link">Terms and Conditions</a> , <a href={pinnitagsPrivacyPolicy} target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a> , <a href={pinnitagsCookiePolicy} target="_blank" rel="noopener noreferrer" className="link">Cookie Policy</a> & <a href={pinnitagsOTPpolicy} target="_blank" rel="noopener noreferrer" className="link"> OTP Policy</a></p>

                    <p>
                      Not registered?
                      <Link to='/form' className='linkgreen'> Sign Up </Link>
                    </p>

                  </Col>
                )}
                {screenWidth > 700 && (
                  <Modal title="" open={true} okText={"yes"} closable={false}
                    footer={[


                    ]}
                    centered={true}
                    className="allmodals"
                  >
                    <div className="text-center" style={{minHeight:'45vh',display:'flex',flexDirection:'column',justifyContent:'center'}} >

                      <div className="login2-image">
                        <Card.Img variant="top" src={logo} className="login2-image mb-3" />

                      </div>


                      <div className="home-text">
                        {/* <h6 className="text-success">Hey there !!!</h6> */}

                        <TextTyper text={"Do you have an OTP for today ?"} interval={50} Markup={"p"} />
                      </div>

                      <div className="mb-3  d-flex justify-content-center ">
                        <Button onClick={handleYes} title='Already have an OTP.' className='text-nowrap mb-1 h-11  w-25 yes-button-login' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" >
                          <TiTick className='mx-1' style={{ width: '15px', height: '15px' }} /> | Yes
                        </Button>
                        <Button onClick={handleNo} title='Do not have an OTP.' className=' text-nowrap h-11 w-25  mb-1 no-button-login' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" >
                          <ImCross className='mx-1' style={{ width: '9px', height: '9px',  }} /> | No
                        </Button>
                      </div>
                      <p className='text-muted tcp '>&nbsp;By clicking sign up, you agree to our <a href={pinnitagsTermsConditions} target="_blank" rel="noopener noreferrer" className="link">Terms and Conditions</a> , <a href={pinnitagsPrivacyPolicy} target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a> , <a href={pinnitagsCookiePolicy} target="_blank" rel="noopener noreferrer" className="link">Cookie Policy</a> & <a href={pinnitagsOTPpolicy} target="_blank" rel="noopener noreferrer" className="link"> OTP Policy</a></p>

                      <p>
                        Not registered?
                        <Link to='/form' className='linkgreen'> Sign Up </Link>
                      </p>

                    </div>

                  </Modal>
                )}
              </>






            )}
            {step === 2 && (

              <Form onSubmit={handleSubmit} className="login-form text-center ">


                <Form.Group  >
                  <Form.Label className="mt-3" >
                    <TextTyper text={"How would you like to login?"} interval={50} Markup={"p"} />
                  </Form.Label>
                  <Row className="switch-row" style={{margin:'0px',padding:'0px', display:'flex', flexDirection:'row',alignItems:'center',justifyContent:'center'}}>


                    <div className="switch-wrapper" style={{ height: 30,margin:'0px',padding:'0px',width:'100%' }}>
                      <SwitchSelector
                        onChange={handleSwitch}
                        options={options}
                        initialSelectedIndex={initialSelectedIndex}
                        backgroundColor={"#fefefe"}
                        fontColor={"#1a1a1c"}
                        wrapperBorderRadius={0}
                        fontSize={12}
                        optionBorderRadius={8}



                      />
                    </div>

                  </Row>

                </Form.Group>



                {contactMethod === "email" && (
                  <Form.Group as={Row}>
                    <Col>
                      <Row className="mt-2">

                      </Row>

                      <Row className="login-inputs">
                        <Form.Control name="login-email" type="email" className="login-email input-box-login input-box mt-0 form-control-sm" placeholder="Email : ex@domain.com" value={email} onChange={(e) => {
                          setErrorMsg()
                          setEmail(e.target.value)
                        }} />

                      </Row>

                    </Col>

                  </Form.Group>
                )}










                <Form.Group className="mb-3" controlId="formBasicEmail">

                  {contactMethod === "phone" && (
                    <Form.Group as={Row}>

                      <Col >
                        <Row>

                        </Row>
                        <Col>


                          <Row className="phone-input-login ">


                          </Row>



                          <Row className="country-code">

                            <PhoneInput
                              country={'ug'}
                              value={contact}
                              localization={{ ug: 'Uganda' }}
                              onChange={(e) => {
                                setErrorMsg()
                                setContact(e)

                              }}
                              isValid={(inputNumber, country) => {

                                if (country.countryCode === "256" && (inputNumber.length > 12)) {
                                  // setPhonelength(false);
                                  return false
                                } else {
                                  // setPhonelength(true);
                                  return true
                                }
                              }}
                              inputProps={{
                                name: 'phone',
                                required: true,

                              }}
                              masks={{ ug: '... ... ...' }}

                              inputStyle={{
                                width: "99.5%",
                                height: "30px",
                                fontSize: "12px"
                              }}

                            />
                          </Row>

                        </Col>

                      </Col>
                    </Form.Group>
                  )}





                </Form.Group>

                <InputGroup className="mb-3 login-inputs" controlId="formBasicPassword">



                  <Form.Control ref={inputRef} className="input-box-login input-box mt-0 form-control-sm" type={checked ? "text" : "password"} placeholder="OTP" value={otp} onChange={(e) => {
                    setErrorMsg()
                    setOtp(e.target.value)
                  }} />
                  <InputGroup.Text onClick={()=>{setChecked(!checked)}}>

                    {checked ? <VscEye style={{fontSize:'13px'}} /> : <VscEyeClosed style={{fontSize:'13px'}} />}
                  </InputGroup.Text>
                  
                </InputGroup>
                <div className='mt-2' style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                  {/* <FormCheck
                    type="checkbox"
                    label="Show OTP."
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    style={{ textAlign: "start" }}
                  /> */}
                </div>


                {errorMsg && <Modal open={otpErrorOpen} title={'error'} onCancel={handleOtppop} onOk={handleOtppop}
                  footer={[


                  ]}
                >
                  <ColoredD color="red">{errorMsg}</ColoredD>

                </Modal>}

                {loginsuccessMsg && <Alert variant="success">{loginsuccessMsg}</Alert>}
                {/* <Button variant="light " type="submit" className='login-button mb-3 '>
                  Login
                </Button> */}
                <Button className='login-button mb-3 ' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                  <CiLogin className='mx-1' style={{ width: '15px', height: '15px' }} />| Login
                </Button>

                <p className='text-muted tcp '>&nbsp;You agree to our <a href={pinnitagsTermsConditions} target="_blank" rel="noopener noreferrer" className="link">Terms and Conditions</a> , <a href={pinnitagsPrivacyPolicy} target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a> , <a href={pinnitagsCookiePolicy} target="_blank" rel="noopener noreferrer" className="link">Cookie Policy</a> & <a href={pinnitagsOTPpolicy} target="_blank" rel="noopener noreferrer" className="link"> OTP Policy</a></p>
                <p style={{ height: '1px' }}>Don't have an OTP? <a className='linkgreen' href='#' onClick={(event) => {
                  event.preventDefault()
                  setStep(3)

                }}>Request </a></p>
                <p>
                  Not registered?
                  <Link to='/form' className='linkgreen'> Sign Up </Link>
                </p>

                <p className="text-muted" style={{ fontSize: "10px", marginBottom: "0px" }}>
                  Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a href="https://pinnisoft.com/" target="_blank" rel="noopener noreferrer" className="link">PinniSOFT</a>  <br />
                  All rights reserved.
                </p>




              </Form>


            )}
            {step === 3 && (
              <Form onSubmit={handleSubmit}  className="login-form text-center">


                <Form.Group className="mt-3 mb-3">
                  <TextTyper text={"How would you want to receive your OTP?"} interval={50} Markup={"p"} />
                  <Row className="switch-row" style={{margin:'0px',padding:'0px', display:'flex', flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <div className="switch-wrapper" style={{ height: 30,margin:'0px',padding:'0px',width:'100%' }}>
                      <SwitchSelector
                        onChange={handleSwitch}
                        options={options}
                        initialSelectedIndex={initialSelectedIndex}
                        backgroundColor={"#fefefe"}
                        fontColor={"#1a1a1c"}
                        wrapperBorderRadius={0}
                        fontSize={12}
                        optionBorderRadius={8}
                        
                      />
                    </div>

                  </Row>

                </Form.Group>



                {contactMethod === "email" && (
                  <Form.Group as={Row}>
                    <Col>
                      <Row>
                        {/* <Form.Label >
                          Please provide us with your email.
                        </Form.Label> */}

                      </Row>

                      <Row className="login-inputs">
                        <Form.Control ref={inputRef} name="login-email" type="email" className="login-email input-box-login input-box mt-0 form-control-sm" placeholder="Email : ex@domain.com" value={email} onChange={(e) => {
                          setSuccessMsg()
                          setOtperror()
                          setEmail(e.target.value)
                        }} />

                      </Row>
                      {otperror && <Modal open={otpErrorOpen} title={'!!!'} onCancel={handleOtppop} onOk={handleOtppop}
                        footer={[


                        ]}
                      >
                        <ColoredD color="red">{otperror}</ColoredD>

                      </Modal>}
                    </Col>



                  </Form.Group>
                )}










                <Form.Group className="mb-3" controlId="formBasicEmail">

                  {contactMethod === "phone" && (
                    <Form.Group as={Row}>

                      <Col >
                        <Row>
                          {/* <Form.Label >
                            Please provide us with your Mobile No.
                          </Form.Label> */}
                        </Row>
                        <Col>


                          <Row className="phone-input-login ">


                          </Row>



                          <Row >
                            <div className="country-code">
                              <PhoneInput
                                country={'ug'}
                                value={contact}
                                localization={{ ug: 'Uganda' }}
                                onChange={(e) => {
                                  setSuccessMsg()
                                  setOtperror()
                                  setContact(e)
                                }}

                                isValid={(inputNumber, country) => {

                                  if (country.countryCode === "256" && (inputNumber.length > 12)) {
                                    //setPhonelength(false);
                                    return false
                                  } else {
                                    //setPhonelength(true);
                                    return true
                                  }
                                }}
                                inputProps={{
                                  name: 'phone',
                                  required: true,

                                }}

                                masks={{ ug: '... ... ...' }}

                                inputStyle={{
                                  width: "99.5%",
                                  height: "30px",
                                  fontSize: "12px"
                                }}

                              />
                            </div>

                          </Row>
                          {otperror && <Modal open={otpErrorOpen} title={'!!!'} onCancel={handleOtppop} onOk={handleOtppop} footer={[


                          ]} >
                            <ColoredD color="red">{otperror}</ColoredD>

                          </Modal>}
                        </Col>

                      </Col>
                    </Form.Group>
                  )}





                </Form.Group>



                {/* <Button variant="light" type="button" className='mb-5  otp-button input-box mt-0 ' onClick={otpRequest}>
                  Request OTP
                </Button> */}

                <Button className='login-button mb-3 ' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" onClick={otpRequest} >
                  <IoReloadSharp className='mx-1' style={{ width: '15px', height: '15px' }} />| Request OTP
                </Button>


                {successMsg && <Modal open={otpErrorOpen} title={'Success'} onCancel={handleOtppop} onOk={handleOtppop} footer={[


                ]} >
                  <ColoredD color="green">{successMsg}</ColoredD>

                </Modal>}
                
                {loginsuccessMsg && <Modal open={otpErrorOpen} title={'OTP ERROR'} onCancel={handleOtppop} onOk={handleOtppop} footer={[


                ]} >

                  <p>{loginsuccessMsg}</p>
                </Modal>}
                <p className='text-muted tcp '>&nbsp;You agree to our <a href={pinnitagsTermsConditions} target="_blank" rel="noopener noreferrer" className="link">Terms and Conditions</a> , <a href={pinnitagsPrivacyPolicy} target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a> , <a href={pinnitagsCookiePolicy} target="_blank" rel="noopener noreferrer" className="link">Cookie Policy</a>& <a href={pinnitagsOTPpolicy} target="_blank" rel="noopener noreferrer" className="link"> OTP Policy</a></p>
                <p style={{ height: '1px' }}>Already have an OTP? <a className='linkgreen' href='#' onClick={(event) => {
                  event.preventDefault()
                  setStep(2)

                }}>Login </a></p>
                <p>
                  Not registered?
                  <Link to='/form' className='linkgreen'> Sign Up </Link>
                </p>
                <p className="text-muted" style={{ fontSize: "10px" }}>
                  Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a href="https://pinnisoft.com/" target="_blank" rel="noopener noreferrer" className="link">PinniSOFT</a> <br />
                  All rights reserved.
                </p>

              </Form>


            )}









          </Card.Body>
        </Card>








      </div>

    )
  }

}



export default Login
















































































