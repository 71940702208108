import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Table, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { getformattedDate, removeSpacesInString } from '../../Utilities/utilities';
import { fetchData } from '../../Utilities/apiRequests';
import * as MdIcons from 'react-icons/md';
import * as GrIcons from 'react-icons/gr';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as GiIcon from 'react-icons/gi';
import { ImCross } from "react-icons/im";
import Pagination from '../Pagination';

const EventReservations = () => {


    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eventInfo, setEventInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [reservations, setReservations] = useState([]);


    const { eid } = useParams();



    useEffect(() => {

        fetchData(`/api/events/registration/reservation/event/${eid}`, setLoading).then((response) => {
            setReservations(response.data.reservations)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event reservations.')
        })


        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })


    }, [refresh])















    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedReservations = reservations?.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = (number) => {
        setCurrentPage(number);
    }


    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>



                <Row>
                    <p style={{ fontWeight: 'bold' }}> Event Reservations </p>
                    <div className='table-responsive'>
                        {paginatedReservations?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile No.</th>
                                        <th>Registered On.</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedReservations?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.mobile}</td>
                                            <td>{getformattedDate(data.createdAt)}</td>
                                            <td> </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No reservations.</p>

                        }
                        {reservations?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={reservations?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }
};

export default EventReservations;
