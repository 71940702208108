import { Form,  ProgressBar, Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from '../api/axios';
import useSectors from '../hooks/getSector';
import useCountries from '../hooks/getCountry';
import useEntities from '../hooks/getEntity';
import { useNavigate } from 'react-router-dom';
import logo from './images/logo.jpg'
import { Image } from 'react-bootstrap'
import ProgressBar1 from './ProgressBar';
import { Card, Alert } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import TextTyper from 'text-type-animation-effect-react'
import { Modal } from 'antd';
import Loader from './Loader';
import ColoredD from './ColorSport';
import * as RxIcon from 'react-icons/rx';
import * as IoIcon from 'react-icons/io';
import * as GiIcon from 'react-icons/gi';
import Cookies from 'js-cookie';
import { twit } from './Notificationpopout';
import registrationPhoto from "./images/regImage.png";
import Recapture from './Recapture';
import {Button} from 'antd';
import { getNairobiTime, validateEmails, validatePhoneNumber } from '../Utilities/utilities';
import regGif from './images/regGif.gif'
import { pinnitagsCookiePolicy, pinnitagsPrivacyPolicy, pinnitagsTermsConditions } from '../Utilities/globals';




function ThreeStepForm() {
  const [step, setStep] = useState(1);
  const [entity, setEntity] = useState('015B4C32-2920-4ECC-88EC-C368C5FFD0C3')
  const [entity2, setEntity2] = useState('')
  const [sector, setSector] = useState('4224382D-89D1-4339-B490-BD3EA009CD4E')
  const [sector2, setSector2] = useState('')
  const [name, setName] = useState('')
  const [contact, setContact] = useState('256')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [country, setCountry] = useState('222')
  const [isChecked, setIschecked] = useState(false)
  const [termserror, setTermserror] = useState()
  const [step1error, setStep1error] = useState()
  const [step3error, setStep3error] = useState()
  const [step4error, setStep4error] = useState()
  const [longitude, setLongitude] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [loading, setLoading] = useState(false)
  const [otpErrorOpen, setotpErrorOpen] = useState(true)
  const [cookypolicy, setCookypolicy] = useState(true);
  const [countrydata, setCountrydata] = useState()
  const [entitydata, setEntitydata] = useState()
  const [sectordata, setSectordata] = useState()
  const [selectedEntity, setSelectedEntity] = useState("Individual");
  const [selectedSector, setSelectedSector] = useState("Education And Training");
  const [selectedCountry, setSelectedCountry] = useState("UGANDA");
  const [phoneLength, setPhonelength] = useState(true);
  const [capture, setCapture] = useState(false);

  const navigate = useNavigate()
  const usesectors = useSectors();
  const usecountries = useCountries()
  const useentities = useEntities()



  useEffect(() => {
    usesectors.then((data) => {

      setSectordata(data)
    })

    usecountries.then((data) => {

      setCountrydata(data)
    })

    useentities.then((data) => {

      setEntitydata(data)
    })
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLongitude(position.coords.longitude)
        setLatitude(position.coords.latitude)
      },
      (error) => console.error(error)
    );


  }, [])



  const RegisterUrl = '/api/auth/register'
  const payload = { esid: sector, eid: entity, cid: country, regname: name, email: email, location: location, phone: contact, sety: entity2, secs: sector2, longitude: longitude, latitude: latitude }
  const register = async () => {
    setLoading(true)
    try {

      //  await validatePhoneNumber(contact);
      //  await validateEmails(email);
      try {
        const response = await axios.post(RegisterUrl,
          JSON.stringify(payload),
          {
            headers: { 'Content-Type': 'application/json' },
          }

        );
        if (response.request.status === 201) {
          setLoading(false)

          sessionStorage.setItem('rid', response.data.data.rid)
          sessionStorage.setItem('email', email)
          sessionStorage.setItem('latitude', latitude)
          sessionStorage.setItem('longitude', longitude)
          navigate('/verification');
          twit("info", "Your registration verification code has been sent to " + email, 5)
        }
        
        
      } catch (err) {
        setLoading(false)
        if (!err?.response) {
          twit("error", 'No Server Response');
        } else if (err.response?.status === 400) {
          //setStep4error(err.response.data.message);
          
          twit("warning", err.response.data.data[0].msg, 3.5);
        } else if (err.response?.status === 401) {
          //setStep4error(err.response.data.message);
          twit("error", err.response.data.message);
        } else {
          // setStep4error(err.response.data.message);
          twit("error", err.response.data.data[0].msg, 2.5);
        }
        
      }
    } catch (error) {
      setLoading(false);
      twit('info', error);
    }


  }



  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateEmail = (email) => {
    return emailPattern.test(email);
  }






  const handleSubmit = (event) => {

    event.preventDefault();
    if (step === 1) {

      if (entity === "5F6BB5C4-8177-47CD-897F-6DB330889D34" && entity2 === '') {
        twit("info", 'please enter your entity type')
      } else {
        setStep1error()
        setStep(2);
      }


    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      if (sector === "EA4BBDFB-1BC2-4931-954F-66C411624C16" && sector2 === '') {
        twit("info", 'please enter your sector type')
      } else {
        setStep3error()
        setStep(4);
      }

    } else if (step === 4) {


      if (contact === '' || contact.length < 12 || name === '' || location === '' || !validateEmail(email) || !phoneLength || !capture || !isChecked) {
        if (name === '') {
          twit("info", 'Your fullname is required.', 4);
        } else if (contact === '' || contact.length < 12) {
          // twit("info", "+" + contact + ' is less than 12 digits required for your country', 3.5);
          twit("info", "Invalid phone number.", 3.5);


        } else if (!validateEmail(email)) {
          twit("info", "Invalid email format.");
        } else if (location === '') {
          twit("info", 'Your location is required.', 3);
        } else if (!phoneLength) {
          twit("info", "+" + contact + ' is longer than 12 digits required for your country', 3);
        } else if (!isChecked) {
          twit("info", 'Please agree to our Terms & Conditions and Privacy policy to continue with your registration.', 5);
        } else if (!capture) {
          twit("info", 'Please verify you are human.', 3);

        }

      } else {
        register();
      }














    }
  }

  const handleBack = () => {
    setStep(step - 1);
  }

  const handleCheckboxChange = () => {
    setIschecked(!isChecked)
    setTermserror()
  }


  const handleOtppop = () => {
    setStep1error();
    setStep3error();
    setStep4error();
    setTermserror();


  }
  const cookieValue = Cookies.get('CookyPolicy');



  useEffect(() => {
    if (cookieValue) {
      setCookypolicy(false);
    }



  }, [])


  const handleCookie = () => {

    Cookies.set('CookyPolicy', true, { expires: 7 });


    setCookypolicy(false);
  }


  if (loading) {
    return (<Loader />)
  } else {
    return (

      <div className='main-main-form-container'>

        {/* <div className="back-wrap1"  ></div>
        <div className="back-wrap2"  ></div>
        <div className="back-wrap3" ></div> */}

        <div className='main-form-container'>





          <div className='formImage-container'>

            {/* <Image src={registrationPhoto} alt="My Image" className='registration-photo' /> */}
            <Image src={regGif} alt="My Image" className='registration-photo' />
            

          </div>

          <div className='form-container'>



            <Row>

              <Col className='form-area-col'>
                <div className='sleeve '>

                  <div className='form-area-image'>
                    <Image src={logo} alt="My Image" />
                  </div>

                  <ProgressBar1 step={step} />

                </div>

                {cookypolicy && <Modal open={true}

                  closable={false}
                  maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                  footer={[


                  ]}
                >

                  <Col  >
                    <Row>
                      <RxIcon.RxCookie style={{ width: '150px', height: '150px', color: "green" }} />
                    </Row>
                    <Row>
                      <h6>Your privacy</h6>
                      <p>By clicking "Accept all cookies",
                        you agree <span style={{ fontWeight: "bold" }}><a style={{ color: "green" }} href='https://pinnisoft.com/' target="_blank" rel="noopener noreferrer" >PinniSOFT</a> </span>can store cookies on your device
                        and disclose information in accordance with our
                        &nbsp;
                        <a style={{ color: "green" }} href={pinnitagsCookiePolicy} target="_blank" rel="noopener noreferrer">Cookie Policy</a>

                      </p>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button onClick={handleCookie} variant="success" type="Button" style={{ maxWidth: '150px', fontSize: '10px' }}>
                        Accept all Cookies
                      </Button>
                    </Row>

                  </Col>




                </Modal>}


                {step === 1 && (




                  <Form onSubmit={handleSubmit} className=" form-step sleeve">

                    <Form.Group controlId="formStep1">
                      <TextTyper text={"Let us understand your business structure..."} interval={50} Markup={"p"} />
                      <Form.Select className='form-selector form-control-sm' onChange={(e) => {
                        
                        setEntity(e.target.value
                        )
                        const selected = entitydata?.filter((data) => {
                          return (data.eid === e.target.value)
                        })

                        setSelectedEntity(selected[0]?.EntityName);

                      }}>
                        <option key={1} value={entity}>{selectedEntity}</option>
                        {entitydata?.map((data) => {
                          return <option className='input-box' key={data.eid} value={data.eid}>{data.EntityName}</option>
                        })}



                      </Form.Select>

                      {entity === "5F6BB5C4-8177-47CD-897F-6DB330889D34" && (
                        <>
                          <Form.Control className='other-input form-control-sm'
                            type="name" placeholder="enter your entity type" value={entity2} onChange={(e) => {
                              setStep1error()
                              setEntity2(e.target.value)
                            }} />

                          {step1error && <Modal open={otpErrorOpen} title={'Missing Input'} onCancel={handleOtppop} onOk={handleOtppop}
                            footer={[


                            ]}
                          >
                            <ColoredD color="red">{step1error}</ColoredD>

                          </Modal>}



                          < IoIcon.IoIosArrowDroprightCircle title='next' onClick={handleSubmit} style={{ height: "40px", width: "40px", color: "gray" }} />
                        </>
                      )}

                    </Form.Group>
                    {entity !== "5F6BB5C4-8177-47CD-897F-6DB330889D34" && (< IoIcon.IoIosArrowDroprightCircle title='next' onClick={handleSubmit} style={{ height: "40px", width: "40px", color: "gray", marginTop: "10px" }} />)}

                  </Form>
                )}
                {step === 2 && (
                  <Form onSubmit={handleSubmit} className="form-step sleeve">
                    <Form.Group controlId="formStep2 ">
                      <TextTyper text={"Let us know the country or region where you are located..."} interval={50} Markup={"p"} />
                      <Form.Select className='form-selector form-control-sm' id='my-select'
                        onChange={(e) => {
                          if (!(e.target.value == '222')) {
                            twit('info', 'We are only available in Uganda currently.')
                          } else {
                            setCountry(e.target.value
                            )

                            const selected = countrydata?.filter((data) => {

                              return (data.Id == e.target.value)
                            })

                            setSelectedCountry(selected[0]?.Name);
                          }



                        }}>
                        <option key={1} value={country}>{selectedCountry}</option>
                        {countrydata?.map((data) => {
                          return <option className='input-box' key={data.Id} value={data.Id}>{data.Name}</option>
                        })}

                      </Form.Select>
                    </Form.Group>
                    <div className='step2-buttons'>
                      {/* <Button title='back' variant="light" onClick={handleBack} className='rounded-circle form-button form-back input-box'>< IoIcon.IoIosArrowBack style={{height:"20px",width:"10px"}}/></Button>
                      <Button title='next' variant="light" type="submit" className='rounded-circle form-button form-next input-box'>< IoIcon.IoIosArrowForward style={{height:"20px",width:"10px"}} /> </Button> */}
                      < IoIcon.IoIosArrowDropleftCircle title='back' onClick={handleBack} style={{ height: "40px", width: "40px", color: "gray" }} />
                      < IoIcon.IoIosArrowDroprightCircle title='next' onClick={handleSubmit} style={{ height: "40px", width: "40px", color: "gray" }} />
                    </div>
                  </Form>
                )}
                {step === 3 && (
                  <Form onSubmit={handleSubmit} className="form-step sleeve">
                    <Form.Group controlId="formStep3 ">
                      <TextTyper text={"Let us know about your service industry..."} interval={50} Markup={"p"} />
                      <Form.Select className='form-selector' onChange={(e) => {
                        
                        setSector(e.target.value
                        )
                        const selected = sectordata?.filter((data) => {
                          return (data.esid === e.target.value)
                        })

                        setSelectedSector(selected[0]?.sname);





                      }}>
                        <option key={1} value={sector}>{selectedSector}</option>
                        {sectordata?.map((data) => {
                          return <option className='input-box' key={data.esid} value={data.esid}>{data.sname}</option>
                        })}


                      </Form.Select>

                      {sector === "EA4BBDFB-1BC2-4931-954F-66C411624C16" && (
                        <>
                          <Form.Control className='mt-2 input-box form-control-sm'
                            type="name" placeholder="Service Industry" value={sector2} onChange={(e) => {
                              setStep3error()
                              setSector2(e.target.value)
                            }} />
                          <div className='step2-buttons'>
                            {/* <Button title='back' variant="light" onClick={handleBack} className='rounded-circle form-button form-back input-box'>< IoIcon.IoIosArrowBack style={{height:"20px",width:"10px"}}/></Button>
                            <Button style={{justifyContent:"space-around"}} title='next' variant="light" type="submit" className=' rounded-circle form-button form-next input-box'> < IoIcon.IoIosArrowForward /> </Button> */}
                            < IoIcon.IoIosArrowDropleftCircle title='back' onClick={handleBack} style={{ height: "40px", width: "40px", color: "gray" }} />
                            < IoIcon.IoIosArrowDroprightCircle title='next' onClick={handleSubmit} style={{ height: "40px", width: "40px", color: "gray" }} />
                          </div>
                          {step3error && <Modal open={otpErrorOpen} title={'INVALID INPUT'} onCancel={handleOtppop} onOk={handleOtppop}
                            footer={[


                            ]}
                          >
                            <ColoredD color="green">{step3error}</ColoredD>

                          </Modal>}


                        </>
                      )}


                    </Form.Group>
                    {sector !== "EA4BBDFB-1BC2-4931-954F-66C411624C16" && (
                      <div className='step2-buttons'>
                        {/* <Button title='back' variant="light" onClick={handleBack} className='rounded-circle form-button form-back input-box'>< IoIcon.IoIosArrowBack style={{height:"20px",width:"10px"}} /></Button>
                        <Button  title='next'  variant="light" type="submit" className='rounded-circle form-button form-next input-box'>< IoIcon.IoIosArrowForward style={{height:"20px",width:"10px"}} /> </Button> */}
                        < IoIcon.IoIosArrowDropleftCircle title='back' onClick={handleBack} style={{ height: "40px", width: "40px", color: "gray" }} />
                        < IoIcon.IoIosArrowDroprightCircle title='next' onClick={handleSubmit} style={{ height: "40px", width: "40px", color: "gray" }} />
                      </div>
                    )}
                  </Form>
                )}
                {step === 4 && (
                  <Form onSubmit={handleSubmit} className="mt-3   step4-form sleeve">
                    <div className='step4-typer'>
                      <TextTyper text={"Lets get started..."} interval={50} Markup={"p"} />
                    </div>



                    <Form.Control className='input-box mt-1 mb-1 form-control-sm'
                      type="name" placeholder={entity === "015B4C32-2920-4ECC-88EC-C368C5FFD0C3" ? " Your name please" : "Company | Business | NGO name"} value={name} onChange={(e) => {
                        setStep4error()
                        setName(e.target.value)
                      }} />



                    {/* <Form.Label>Phone</Form.Label> */}

                    <PhoneInput
                      country={'ug'}
                      value={contact}
                      localization={{ ug: 'Uganda' }}
                      isValid={(inputNumber, country) => {

                        if (country.countryCode === "256" && (inputNumber.length > 12)) {
                          setPhonelength(false);
                          return false
                        } else {
                          setPhonelength(true);
                          return true
                        }
                      }}
                      onChange={(e, country) => {

                        setStep4error()
                        setContact(e)

                      }}
                      disableDropdown
                      countryCodeEditable={false}
                      masks={{ ug: '... ... ...' }}
                      inputProps={{
                        name: 'phone',
                        required: true,

                      }}


                      inputStyle={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px"
                      }}

                    />
                    <Form.Group className="mb-1 mt-1" controlId="formBasicEmail">
                      {/* <Form.Label className='mt-1'>Email</Form.Label> */}
                      <Form.Control className='input-box mt-0 form-control-sm' type="text" placeholder="Email : ex@domain.com" value={email} onChange={(e) => {
                        setStep4error()
                        setEmail(e.target.value)
                      }} />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="text">
                      {/* <Form.Label>Location</Form.Label> */}
                      <Form.Control className='input-box  mt-0 form-control-sm' type="text" placeholder="Address: Kampala " value={location} onChange={(e) => {
                        setStep4error()
                        setLocation(e.target.value)
                      }} />
                    </Form.Group>

                    {step4error && <Modal open={otpErrorOpen} title={'!!!'} onCancel={handleOtppop} onOk={handleOtppop}
                      footer={[


                      ]}
                    >
                      <ColoredD color="red">{step4error}</ColoredD>

                    </Modal>}






                    <div className='step4-terms'>

                      <Form.Check
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <p className='text-muted'>&nbsp;I agree to the <a href={pinnitagsTermsConditions} target="_blank" rel="noopener noreferrer" className="link">Terms and Conditions</a> & <a href={pinnitagsPrivacyPolicy} target="_blank" rel="noopener noreferrer" className="link">Privacy Policy</a></p>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>

                    </div>
                    {!capture && (
                      <div className='mb-2' style={{ padding: "0px", display: 'flex', alignItems: "center", justifyContent: "space-around" }}>
                        <Recapture setRecapture={setCapture} />

                      </div>
                    )}

                    <div className='step4-buttons '>
                      {/* <Button title='back' variant="light" onClick={handleBack} className='rounded-circle form-button  form-back input-box'>< IoIcon.IoIosArrowBack style={{height:"20px",width:"10px"}} /></Button> */}
                      < IoIcon.IoIosArrowDropleftCircle title='back' onClick={handleBack} style={{ height: "40px", width: "40px", color: "gray", margin: "10px" }} />
                      {/* <Button variant="light" type="submit" className=' form-button form-reg-submit input-box '>Register Account</Button> */}

                      <Button className=' form-button form-reg-submit input-box ' style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                        <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Register Account
                      </Button>
                    </div>


                    <div>
                      <p className='text-center mt-1'>&nbsp;Already have an account? <a href="/" className="link"> Login</a></p>
                    </div>
                    <div className='mt-1' style={{ textAlign: "center" }}>
                      <p className="text-muted" style={{ fontSize: "10px", justifySelf: "center" }}>
                        Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a href="https://pinnisoft.com/" target="_blank" rel="noopener noreferrer" className="link">PinniSOFT</a>  <br />
                        All rights reserved.
                      </p>
                    </div>
                    {termserror && <Modal open={otpErrorOpen} title={'Terms Acceptance'} onCancel={handleOtppop} onOk={handleOtppop}
                      footer={[


                      ]}
                    >
                      <ColoredD color="red">{termserror}</ColoredD>

                    </Modal>}




                  </Form>

                )}

              </Col>
            </Row>
            {step !== 4 && (<div>
              <p className='text-center mt-3'>&nbsp;Already have an account? <a href="/" className="link"> Login</a></p>
            </div>)}

            {step !== 4 && (
              <div className='mt-5' style={{ textAlign: "center" }}>
                <p className="text-muted" style={{ fontSize: "10px", justifySelf: "center" }}>
                  Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a href="https://pinnisoft.com/" target="_blank" rel="noopener noreferrer" className="link">PinniSOFT</a>  <br />
                  All rights reserved.
                </p>
              </div>


            )}


          </div>

        </div>

      </div>


    );
  }


}

export default ThreeStepForm;
