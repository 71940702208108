import { useState, useRef, useEffect } from 'react';
import { Form, Button, Alert, Container, InputGroup, FormControl, InputGroupText } from 'react-bootstrap';
import axios from '../api/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import Loader from './Loader';
import TextTyper from 'text-type-animation-effect-react'
import FiveDigitCodeInput from './FiveDigitInput';
import logo from './images/logo.jpg'
import { twit } from './Notificationpopout';
import { getNairobiTime } from '../Utilities/utilities';

function EmailVerification() {

  const [errorMsg, setErrorMsg] = useState('');

  const rid = sessionStorage.getItem('rid');
  // const latitude = sessionStorage.getItem('latitude')
  // const longitude = sessionStorage.getItem('longitude')
  const navigate = useNavigate()


  const ReverificationURL = '/api/auth/re-verify/email/'



  const [digit1, setDigits1] = useState('');
  const [digit2, setDigits2] = useState('');
  const [digit3, setDigits3] = useState('');
  const [digit4, setDigits4] = useState('');
  const [digit5, setDigits5] = useState('');
  const [loading, setLoading] = useState(false);
  const [rerequest, setRerequest] = useState(false);
  const [rerequestEmail, setRerequestEmail] = useState("");
  const [reverify, setReverify] = useState(false);
  const [latitude, setLatitude] = useState(" ");
  const [longitude, setLongitude] = useState(" ");


  const VerificationURL = reverify ? `/api/auth/re-verify/email/${rid}` : `/api/auth/verify/email/${rid}`




  const verificationcode = digit1 + digit2 + digit3 + digit4 + digit5

  const VerificationPayload = { code: verificationcode }

  const reVerificationPayload = { email: rerequestEmail, latitude:1, longitude:1 }

  

  const requestCode = async () => {
    setLoading(true)
    try {
      const response = await axios.post(ReverificationURL,
        JSON.stringify(reVerificationPayload),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );





      if (response.request.status === 200) {
        setLoading(false);
        sessionStorage.setItem('rid', response.data.rid);
        setRerequest(false);
        setErrorMsg();
        twit('success',response.data.message)

      }else if (response.request.status === 202) {

        setLoading(false)

        twit("success", response.data.message, 3)
        navigate('/');


      } else  {
        setLoading(false)
        
        alert(response.data.message)
      }


    } catch (err) {
      setLoading(false)
      if (!err?.response) {
        twit("error", 'No Server Response');
      } else if (err.response?.status === 400) {
        twit("info", err.response.data.message);
      } else if (err.response?.status === 401) {
        twit("info", err.response.data.message);
      } else {
        twit("info", err.response.data.message);
      }

    }

  }




  const handleSubmitverification = async (e) => {
    e.preventDefault();
    if (!reverify && !rid) {
      setReverify(true);
      setRerequest(true);
    } else {
      if (verificationcode.length === 5) {
        setLoading(true)
        try {
          const response = await axios.post(VerificationURL,
            JSON.stringify(VerificationPayload),
            {
              headers: { 'Content-Type': 'application/json', },
            }
          );

            


          //setAuth({  accessToken });
          if (response.request.status === 201) {

            setLoading(false)

            twit("success", "Registration completed Successfully", 3)
            navigate('/');


          }

          if (response.request.status === 202) {

            setLoading(false)
  
            twit("success", response.data.message, 3)
            navigate('/');
  
  
          }
          

        } catch (err) {
          setLoading(false)
          if (!err?.response) {
            twit("error", 'No Server Response');
          } else if (err.response?.status === 400) {
            twit("info", err.response.data.message);
          } else if (err.response?.status === 401) {
            twit("info", err.response.data.message);
          } else if (err.response?.status === 404) {
            twit("error", err.response.data.message);
            setRerequest(true);
            setReverify(true);

          } else {
            twit("error", err.response.data.message);
          }

        }

      } else {
        twit("info", 'verification code should contain 5 characters')
      }

    }



  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userRid = searchParams.get('rid');
  const userCode = searchParams.get('code');
  const mode = searchParams.get('mode');


  const verifyCodeOnLoad = async (code,rid)=>{
    if (code.length === 5) {
      setLoading(true)
      try {
        const response = await axios.post(mode==1?`/api/auth/verify/email/${rid}`:`/api/auth/re-verify/email/${rid}`,
          JSON.stringify({ code}),
          {
            headers: { 'Content-Type': 'application/json', },
          }
        );




        //setAuth({  accessToken });
        if (response.request.status === 201) {

          setLoading(false)

          twit("success", "Registration completed Successfully", 3)
          navigate('/');


        }

        if (response.request.status === 202) {

          setLoading(false)

          twit("success", response.data.message, 3)
          navigate('/');


        }


      } catch (err) {
        setLoading(false)
        if (!err?.response) {
          twit("error", 'No Server Response');
        } else if (err.response?.status === 400) {
          twit("info", err.response.data.message);
        } else if (err.response?.status === 401) {
          twit("info", err.response.data.message);
        } else if (err.response?.status === 404) {
          twit("error", err.response.data.message);
          setRerequest(true);
          setReverify(true);

        } else {
          twit("error", err.response.data.message);
        }

      }

    } else {
      twit("info", 'verification code should contain 5 characters')
    }
  }




  useEffect(() => {

    if (rerequest) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
        },
        (error) => console.error(error)
      );
    }



  }, [rerequest]);

  useEffect(() => {
    if(userCode&&userRid&&mode){
      verifyCodeOnLoad(userCode,userRid)
    }
    
  }, []);







  if (loading) {
    return (<Loader />)
  } else {


    return (
      <div className='main-verify-container'>
        {/* <div className="back-wrapper1"  ></div>
        <div className="back-wrapper2"  ></div>
        <div className="back-wrapper3" ></div> */}
        <Modal closable={false} width={400} open={true}
          footer={[


          ]}
          className="allmodals"
        >

          <Container className='verify-container'>
            <img src={logo} className='email-verification-image' />
            <Form onSubmit={handleSubmitverification} style={{ marginTop: "20px" }}>
              <Form.Group controlId="formBasicEmail">
                {!rerequest && (
                  <TextTyper text={"Enter the registration verification code to complete registration..."} interval={50} Markup={"p"} />
                )}



              </Form.Group>

              {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

              {!rerequest && (

                <div>
                  <FiveDigitCodeInput

                    setValue1={setDigits1}
                    setValue2={setDigits2}
                    setValue3={setDigits3}
                    setValue4={setDigits4}
                    setValue5={setDigits5}

                  />


                </div>



              )}







              {rerequest && (
                <div style={{ marginTop: "20px" }}>

                  <TextTyper text={"Enter your email and request a new code..."} interval={50} Markup={"p"} />

                  <FormControl
                    className='input-box form-control-sm'
                    value={rerequestEmail}
                    onChange={(e) => {
                      setRerequestEmail(e.target.value);
                    }}
                    type='email'
                    placeholder='Email : ex@domain.com'
                  />


                </div>

              )}



              <p className='text-muted tcp ' style={{ marginTop: "20px" }}>&nbsp;You agree to our <a href="/terms" className="link">Terms and Conditions</a> , <a href="/terms" className="link">Privacy Policy</a> & <a href="/terms" className="link"> OTP Policy</a></p>

              {!rerequest && (

                <div>

                  <Button className='verify-button' variant="success" type='submit' style={{ marginTop: "8px" }}>
                    Verify Account
                  </Button>
                </div>



              )}

              {rerequest && (
                <div>


                  <Button className='verify-button' style={{ marginTop: "8px" }} variant="primary" type='button' onClick={requestCode}>
                    Request Verification code
                  </Button>

                </div>

              )}
              {!rerequest&&(
                <p style={{height:'2px', fontSize:'10px'}}><a style={{color:'green'}} href='#' onClick={(event)=>{
                  event.preventDefault()
                  setReverify(true);
                  setRerequest(true);
               }}>Click here </a>to request a new code.</p> 
              )}
             

              <p className="text-muted" style={{ fontSize: "8px", marginTop: "5px" }}>
                Copright &copy; {getNairobiTime().slice(0,4)} PinniTAGS is a product of <a style={{ color: "green" }} href="https://pinnisoft.com/">PinniSOFT</a> <br />
                All rights reserved.
              </p>

            </Form>
          </Container>
        </Modal>

      </div>



    );
  }
}

export default EmailVerification;
