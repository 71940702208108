import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/NavBar";
import Sidebar from "../components/Dashboard2";
import Mainfooter from "../components/MainFooter";

const MainLayout = ({ profile }) => {
  const [open, setOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handlesidebar = () => {
    setOpen(!open);
  };


  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 1000) {
      setOpen(false);
    }

    if (screenWidth > 1000) {
      setOpen(true);
    }

  }, [screenWidth]);

  return (
    <div className="main-layout">
      <div className="sidebar-container" style={{ width: `${open ? "20%" : "0"}` }}>
        <Sidebar open={open} showSidebar={handlesidebar} />
      </div>
      <div className="navbar-outlet-container" style={{ width: `${open ? '80%' : '100%'}` }}>
        <div style={{ width: "100%" }}>
          <Navbar isopen={open} showSidebar={handlesidebar} profile={profile} />
        </div>

        <div className="main-layout-outlet-container">
          <Outlet />
        </div>

        <div className="main-layout-footer-container">
          <Mainfooter />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
