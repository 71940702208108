import { Button } from 'antd';
import React, { useEffect } from 'react'
import { Card, Row, Col, Table, Form } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Rectangle, PieChart, Pie, Cell, ResponsiveContainer, } from 'recharts';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData } from '../Utilities/apiRequests';
import { twit } from './Notificationpopout';
import { useState } from 'react';
import Loader from './Loader';
import * as FcIcon from 'react-icons/fc';
import * as BiIcon from 'react-icons/bi';
import { formatPrice, getNairobiTime } from '../Utilities/utilities';
import ColorCube from './ColorCube';
import { comissionPercentage } from '../Utilities/globals';
import ContainedLoader from './ContainedLoader';

const EventStatistics = () => {

  const navigate = useNavigate();
  const { eid } = useParams();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [eventInfo, setEventInfo] = useState([]);
  const [eventTicketData, setEventTicketData] = useState({});
  const [zoneTicketData, setZoneTicketData] = useState([]);
  const [platformTicketData, setPlatformTicketData] = useState([]);
  const [eventPassData, setEventPassData] = useState({});
  const [categoryPassData, setCategoryPassData] = useState([]);
  const [eventCouponData, setEventCouponData] = useState({});
  const [zoneCouponData, setZoneCouponData] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [ticketSalesZone, setTicketSalesZone] = useState([]);
  const [ticketSalesPlatform, setTicketSalesPlatform] = useState([]);
  const [passesAwarded, setPassesAwarded] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [onSmallScreen, setOnSmallScreen] = useState(false);
  const [eventCouponDataPass, setEventCouponDataPass] = useState({});
  const [categoryCouponData, setCategoryCouponData] = useState([]);
  const [couponDataPass, setCouponDataPass] = useState([]);
  const [starts, setStarts] = useState('');
  const [ends, setEnds] = useState('');
  const [eventPassCheckerData, setEventPassCheckerData] = useState({});
  const [eventPassCheckerPieData, setEventPassCheckerPieData] = useState([]);


  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);


  useEffect(() => {
    setOnSmallScreen((screenWidth < 770))
  }, [screenWidth]);


  useEffect(() => {


    fetchData(`/api/events/statistics/tickets/${eid}?startDate=${starts}&endDate=${ends}`, setLoading).then((response) => {
      setEventTicketData(response.data.eventData)
      setZoneTicketData(response.data.zoneData)
      setPlatformTicketData(response.data.platformData)
    }).catch((error) => {
      twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading ticket stats.')
    })

    fetchData(`/api/events/statistics/passes/${eid}`, setLoading1).then((response) => {
      setEventPassData(response.data.eventData)
      setCategoryPassData(response.data.categoryData)
    }).catch((error) => {
      twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading pass stats.')
    })

    fetchData(`/api/events/statistics/passCheckersv2/${eid}?startDate=${starts}&endDate=${ends}`, setLoading5).then((response) => {
      setEventPassCheckerData(response.data)
    }).catch((error) => {
      twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading pass checker event stats.')
    })

    fetchData(`/api/events/statistics/coupons/${eid}?start=${starts}&end=${ends}`, setLoading2).then((response) => {
      setEventCouponData(response.data.eventData)
      setZoneCouponData(response.data.zoneData)
      setCouponData(response.data.couponTypeData)
    }).catch((error) => {
      twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading ticket coupon stats.')
    })


    fetchData(`/api/events/statistics/pass/coupons/${eid}`, setLoading4).then((response) => {
      setEventCouponDataPass(response.data.eventData)
      setCategoryCouponData(response.data.categoryData)
      setCouponDataPass(response.data.couponTypeData)
    }).catch((error) => {
      twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading pass coupon stats.')
    })



    fetchData(`/api/events/details/${eid}`, setLoading3).then((response) => {
      setEventInfo(response.data.eventInfo)
    }).catch((error) => {
      twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
    })


  }, [refresh, starts, ends])






  useEffect(() => {

    if (zoneTicketData.length > 0) {
      setTicketSalesZone(zoneTicketData.map((data) => {
        return { name: data?.name, value: data?.sold, revenue: data?.revenue, cost: data?.cost, costEarlyBird: data?.costEarlyBird, valueStandard: data?.soldStandard, valueEarlyBird: data?.soldEarlyBird, revenueStandard: data?.revenueStandard, revenueEarlyBird: data?.revenueEarlyBird }
      }))
    }

  }, [zoneTicketData])


  useEffect(() => {

    if (platformTicketData.length > 0) {
      setTicketSalesPlatform(platformTicketData.map((data) => {
        return { name: data?.name, value: data?.sold, revenue: data?.revenue }
      }))
    }

  }, [platformTicketData])



  useEffect(() => {

    if (eventPassCheckerData) {
      setEventPassCheckerPieData(
        [
          { name: 'Active', value: eventPassCheckerData?.active },
          { name: 'Inactive', value: eventPassCheckerData?.inactive }
        ]
      )
    }

  }, [eventPassCheckerData])



  useEffect(() => {

    if (categoryPassData.length > 0) {
      setPassesAwarded(categoryPassData.map((data) => {
        return { name: data?.name, value: data?.awarded }
      }))
    }

  }, [categoryPassData])



  const renderTicketSalesLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
    const item = ticketSalesZone[index];

    // Customize the label content here
    //const label = `${item.name} (${item.value}) (${item.revenue})`;
    const label = `(${item.value}) | ${formatPrice(item.revenue)} UGX `;

    return (
      <text style={{ fontSize: onSmallScreen ? '7px' : '12px' }} x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" key={`label-${index}`}>
        {label}
      </text>
    );
  };


  const getSectionDataByName = (label) => {

    const ticketData = ticketSalesZone.filter((data) => {
      return data?.name == label
    })[0]

    if (ticketData) {
      return ticketData
    }

    return "";
  };

  const CustomTooltip = ({ active, payload, label }) => {




    const alldata = payload[0]
    const salesData = alldata?.payload
    const textColor = salesData?.fill



    if (active && payload && payload.length) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', backgroundColor: 'white', padding: '5px', border: '1px solid gray' }}>

          <p className='mt-0 mb-1' style={{ color: `black`, fontWeight: 'bold', fontSize: '13px' }} >{salesData?.name}</p>
          <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />
          <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}>Total Sold:</span> {salesData?.value}</p>
          <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}>Total Revenue:</span> {`${formatPrice(salesData?.revenue)} UGX`}</p>

        </div>
      );
    }

    return null;
  };


  const CustomTooltipPass = ({ active, payload, label }) => {




    const alldata = payload[0]
    const salesData = alldata?.payload
    const textColor = salesData?.fill



    if (active && payload && payload.length) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', backgroundColor: 'white', padding: '5px', border: '1px solid gray' }}>

          <p className='mt-0 mb-1' style={{ color: `black`, fontWeight: 'bold', fontSize: '13px' }} >{salesData?.name}</p>
          <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />
          <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}> Awarded:</span> {salesData?.value}</p>

        </div>
      );
    }

    return null;
  };


  const CustomTooltipPassCheckers = ({ active, payload, label }) => {




    const alldata = payload[0]
    const salesData = alldata?.payload
    const textColor = salesData?.fill



    if (active && payload && payload.length) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', backgroundColor: 'white', padding: '5px', border: '1px solid gray' }}>

          <p className='mt-0 mb-1' style={{ color: `black`, fontWeight: 'bold', fontSize: '13px' }} >{salesData?.name}</p>
          <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />
          <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}>Total:</span> {salesData?.value}</p>
          

        </div>
      );
    }

    return null;
  };

  const CustomTooltipRegistration = ({ active, payload, label }) => {




    const alldata = payload[0]
    const salesData = alldata?.payload
    const textColor = salesData?.fill



    if (active && payload && payload.length) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', backgroundColor: 'white', padding: '5px', border: '1px solid gray' }}>

          <p className='mt-0 mb-1' style={{ color: `black`, fontWeight: 'bold', fontSize: '13px' }} >{salesData?.name}</p>
          <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />
          <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}> Total:</span> {salesData?.value}</p>

        </div>
      );
    }

    return null;
  };


  const renderTicketSalesLabelPlatform = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
    const item = ticketSalesPlatform[index];

    // Customize the label content here
    //const label = `${item.name} (${item.value}) (${item.revenue})`;
    const label = `(${item.value}) | ${formatPrice(item.revenue)} UGX `;

    return (
      <text style={{ fontSize: onSmallScreen ? '7px' : '12px' }} x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" key={`label-${index}`}>
        {label}
      </text>
    );
  };


  // custom legend

  // const CustomZoneSalesLegend = ({ data }) => {
  //   return (
  //     <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
  //       {data.map((item, index) => (
  //         <div key={`legend-${index}`} style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
  //           <ColorCube colorCode={(COLORS[index % COLORS.length])} /> <p className='mt-0 mb-0' style={{ marginLeft: '5px', color: (COLORS[index % COLORS.length]) }}>{item.name} {item.value} | {formatPrice(item.revenue)} UGX</p>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  // statistics calculations

  const totalSalesAmount = eventTicketData ? eventTicketData.revenue : 0
  const totalSalesNumber = eventTicketData ? eventTicketData.sold : 0
  const totalVendorCollections = eventTicketData ? Number(eventTicketData.vendorCollections) : 0
  const totalVendorCommission = eventTicketData ? Number(eventTicketData.vendorCommission) : 0
  const totalToDeduct = (eventTicketData && platformTicketData) ? ((platformTicketData.filter((data) => {
    return data.name == 'POS'
  })[0]?.revenue) + (platformTicketData.filter((data) => {
    return data.name == 'TAGS'
  })[0]?.revenue)) : 0



  // const totalSalesAmountLessPOS = (eventTicketData && platformTicketData) ? (eventTicketData.revenue - (platformTicketData.filter((data) => {
  //   return data.name == 'POS'
  // })[0]?.revenue)) : 0


  const totalSalesAmountLessPOSandTAGs = (eventTicketData && platformTicketData) ? (eventTicketData.revenue - totalToDeduct) : 0




  const totalDeductable = (totalSalesAmountLessPOSandTAGs * comissionPercentage)



  const totalRaised = (eventPassData && eventTicketData) ? (eventTicketData.sold + eventPassData.awarded) : 0
  const ticketRegistration = (eventTicketData.registered) ? eventTicketData.registered : 0
  const passRegistration = (eventPassData?.registered) ? eventPassData.registered : 0
  const totalRegistered = ticketRegistration + passRegistration
  const notRegistered = (totalRaised && totalRegistered) ? (totalRaised - totalRegistered) : 0

  const registrationData = [
    {
      "name": "Not Registered",
      "value": notRegistered,
      "color": 'gray'
    },
    {
      "name": "On Pass",
      "value": passRegistration,
      "color": 'gold'
    },

    {
      "name": "On Ticket",
      "value": ticketRegistration,
      "color": 'green'
    },


  ];





  // Colors for the Pie Chart
  //const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


  const COLORS = [
    '#0088FE',  // Blue (base)
    '#00C49F',  // Teal (base)
    '#FFBB28',  // Orange (base)
    '#FF8042',  // Dark Orange (base)
    '#33aaff',  // Lighter Blue (lightened from #0088FE)
    '#ffde5a',  // Yellow-Orange (analogous to #FFBB28)
    '#00ceff',  // Turquoise (triadic to #FF8042)

    '#A6CEE3',  // Light Blue (ColorBrewer2)
    '#1F77B4',  // Medium Blue (ColorBrewer2)
    '#FF7F0E',  // Bright Orange (Tableau)
    '#539E0B',  // Dark Green (Tableau)
    '#9467BD',  // Purple (accessible)

    // Shades of gray for emphasis (ensure sufficient contrast)
    // '#cccccc',
    // '#e0e0e0',
    // '#f2f2f2',

    // Additional contrasting colors (test for accessibility)
    '#f00',    // Red
    '#0f0',    // Green
    '#00f',    // Blue (darker)
    '#ffc0cb', // Light Pink
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  const tableStyles = {
    backgroundColor: '#f8f9fa', // Light gray background
    fontSize: '10px',
  };

  const headerStyles = {
    backgroundColor: '#343a40',
    color: '#fff',
  };

  const rowStyles = {
    height: '1px',
    padding: '0px',
    margin: '0px'
  };

  const rowCellStyles = {
    paddingTop: '2px',
    paddingBottom: '2px'
  }





  if (loading3) {
    return (<Loader />);
  } else {
    return (
      <div style={{ fontSize: '12px' }}>




        <Row>
          {/* <div className='d-flex flex-row justify-content-center w-100'>
            <h1 style={{ fontSize: '20px', fontWeight: 'bold' }}>{eventData?.name} Statistics <FcIcon.FcStatistics className='mx-1' style={{ width: '20px', height: '20px' }} /></h1>
          </div> */}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
          </div>

        </Row>

        <Row>
          <div className='d-flex flex-row justify-content-end w-100'>
            <Button loading={loading || loading1 || loading2 || loading3 || loading4} onClick={() => { setRefresh(!refresh) }} title='Refresh Statistics' style={{ backgroundColor: "#FFFFFF", color: 'black', fontSize: '10px', margin: '4px' }} variant="primary" type="primary" >
              {(loading || loading1 || loading2 || loading3 || loading4) ? <></> : <BiIcon.BiRefresh className='mx-1' style={{ width: '15px', height: '15px' }} />}| Refresh
            </Button>

          </div>

        </Row>




        <Row>






          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Statistics Period</Card.Title>
                <Row>
                  <Col md={6} className='my-2'>
                    <Form.Group className='my-2' controlId="eventDate">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                        <div className='mx-2 FormLabels'>
                          <p style={{ margin: 0 }}>Start</p>
                        </div>
                        <Form.Control
                          className=' input-box-events form-control-sm'
                          type="datetime-local"
                          name="startDate"
                          value={starts}
                          onChange={(e) => {
                            setStarts(e.target.value)
                          }}

                        />
                      </div>

                    </Form.Group>
                  </Col>
                  <Col md={6} className='my-2'>

                    <Form.Group className='my-2' controlId="eventDate">

                      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                        <div className='mx-2 FormLabels'>
                          <p style={{ margin: 0 }}>End</p>
                        </div>
                        <Form.Control
                          className=' input-box-events form-control-sm'
                          type="datetime-local"
                          name="endDate"
                          value={ends}
                          onChange={(e) => {
                            setEnds(e.target.value)

                          }}
                          min={starts}
                        />
                      </div>

                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

        </Row>





        <Row className='my-2'>
          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Ticket Sales | ZONE </Card.Title>

                {loading ?
                  <ContainedLoader /> :

                  <Row>
                    {ticketSalesZone.length > 0 ?

                      <>
                        <Col md={6} style={{ minHeight: '100%' }}>

                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ResponsiveContainer width='100%' height={300}>
                              <PieChart width={300} height={150}>
                                <Pie data={ticketSalesZone} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={onSmallScreen ? 70 : 100}
                                  // label={renderCustomizedLabel}
                                  labelLine={false}
                                >
                                  {ticketSalesZone.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                  ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />

                              </PieChart>
                            </ResponsiveContainer>
                          </div>

                        </Col>
                        <Col md={6}>
                          {/* {ticketSalesZone.map((item, index) => (
                            <>
                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <p style={{ margin: 0, fontWeight: 'bold' }}>{item?.name}</p>
                              </div>
                              <ul>
                                <li key={`ticket-${index}`}>
                                  <span style={{ fontWeight: 'bold' }}>Sold:</span> {item?.value}
                                </li>
                                <li key={`ticket-${index}`}>
                                  <span style={{ fontWeight: 'bold' }}>Revenue:</span>  {formatPrice(item?.revenue)} UGX
                                </li>
                              </ul>

                            </>

                          ))} */}

                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Standard</p>
                          </div>

                          <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                            <Table striped bordered hover style={tableStyles}>
                              <thead>
                                <tr style={headerStyles}>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                  <th style={{ ...rowCellStyles }}>Zone</th>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No. of Tickets </th>
                                  <th style={{ textAlign: "right", ...rowCellStyles }}>Ticket Value (UGX) </th>
                                  <th style={{ textAlign: "right", ...rowCellStyles }}>Revenue Generated (UGX)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ticketSalesZone.map((item, index) => (
                                  <tr key={index} style={{ ...rowStyles }}>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                    <td style={{ backgroundColor: COLORS[index % COLORS.length], fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.valueStandard)}</td>
                                    <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.cost)}</td>
                                    <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.revenueStandard)} </td>
                                  </tr>
                                ))}

                              </tbody>
                            </Table>
                          </div>

                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Early Bird</p>
                          </div>

                          <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                            <Table striped bordered hover style={tableStyles}>
                              <thead>
                                <tr style={headerStyles}>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                  <th style={{ ...rowCellStyles }}>Zone</th>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No. of Tickets </th>
                                  <th style={{ textAlign: "right", ...rowCellStyles }}>Ticket Value (UGX) </th>
                                  <th style={{ textAlign: "right", ...rowCellStyles }}>Revenue Generated (UGX)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ticketSalesZone.map((item, index) => (
                                  <tr key={index} style={{ ...rowStyles }}>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                    <td style={{ backgroundColor: COLORS[index % COLORS.length], fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.valueEarlyBird)}</td>
                                    <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.costEarlyBird)}</td>
                                    <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.revenueEarlyBird)} </td>
                                  </tr>
                                ))}

                              </tbody>
                            </Table>
                          </div>


                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Totals</p>
                          </div>

                          <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                            <Table striped bordered hover style={tableStyles}>
                              <thead>
                                <tr style={headerStyles}>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                  <th style={{ ...rowCellStyles }}>Zone</th>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No. of Tickets </th>
                                  {/* <th style={{ textAlign: "right", ...rowCellStyles }}>Ticket Value (UGX) </th> */}
                                  <th style={{ textAlign: "right", ...rowCellStyles }}>Revenue Generated (UGX)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ticketSalesZone.map((item, index) => (
                                  <tr key={index} style={{ ...rowStyles }}>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                    <td style={{ backgroundColor: COLORS[index % COLORS.length], fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                    {/* <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.cost)}</td> */}
                                    <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.revenue)} </td>
                                  </tr>
                                ))}

                              </tbody>
                            </Table>
                          </div>

                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Consolidated Totals</p>
                          </div>
                          <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                            <ul>

                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Tickets:</span> {formatPrice(totalSalesNumber)}</p>
                              </li>
                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Vendor Collections Amount:</span> {formatPrice(totalVendorCollections)} UGX </p>
                              </li>
                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Vendor Commission Amount:</span> {formatPrice(totalVendorCommission)} UGX </p>
                              </li>
                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Amount:</span> {formatPrice(totalSalesAmount)} UGX </p>
                              </li>
                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Amount Less Vendor Commission:</span> {formatPrice(Number(totalSalesAmount) - totalVendorCommission)} UGX </p>
                              </li>


                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Expected Amount:</span> {formatPrice(totalSalesAmountLessPOSandTAGs - totalDeductable)} UGX </p>
                              </li>

                            </ul>
                          </div>

                        </Col>
                      </>

                      :

                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                      </div>

                    }

                  </Row>

                }


              </Card.Body>
            </Card>
          </Col>

          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Ticket Sales | PLATFORM</Card.Title>
                {loading ?
                  <ContainedLoader /> :

                  <Row>

                    {ticketSalesPlatform.length > 0 ?

                      <>
                        <Col md={6} style={{ minHeight: '100%' }}  >

                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ResponsiveContainer width='100%' height={300}>
                              <PieChart width={200} height={150}>
                                <Pie data={ticketSalesPlatform} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={onSmallScreen ? 70 : 100}
                                  // label={renderCustomizedLabel}
                                  labelLine={false}
                                >
                                  {ticketSalesPlatform.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                  ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                              </PieChart>

                            </ResponsiveContainer>
                          </div>

                        </Col>
                        <Col md={6}>
                          {/* {ticketSalesPlatform.map((item, index) => (
                            <>
                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <p style={{ margin: 0, fontWeight: 'bold' }}>{item?.name}</p>
                              </div>
                              <ul>
                                <li key={`ticket-${index}`}>
                                  <span style={{ fontWeight: 'bold' }}>Sold:</span> {item?.value}
                                </li>
                                <li key={`ticket-${index}`}>
                                  <span style={{ fontWeight: 'bold' }}>Revenue:</span>  {formatPrice(item?.revenue)} UGX
                                </li>
                              </ul>

                            </>

                          ))} */}

                          <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                            <Table striped bordered hover style={tableStyles}>
                              <thead>
                                <tr style={headerStyles}>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                  <th style={{ ...rowCellStyles }}>Platform</th>
                                  <th style={{ textAlign: "center", ...rowCellStyles }}>No. of Tickets </th>
                                  <th style={{ textAlign: "right", ...rowCellStyles }}>Revenue Generated (UGX)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ticketSalesPlatform.map((item, index) => (
                                  <tr key={index} style={{ ...rowStyles }}>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                    <td style={{ backgroundColor: COLORS[index % COLORS.length], fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                    <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                    <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.revenue)} </td>
                                  </tr>
                                ))}

                              </tbody>
                            </Table>
                          </div>
                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Consolidated Totals</p>
                          </div>
                          <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                            <ul>

                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Tickets:</span> {formatPrice(totalSalesNumber)}</p>
                              </li>
                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Consolidated Amount:</span> {formatPrice(totalSalesAmount)} UGX </p>
                              </li>

                              <li >
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Expected Amount:</span> {formatPrice(totalSalesAmountLessPOSandTAGs - totalDeductable)} UGX </p>
                              </li>

                            </ul>
                          </div>
                        </Col>
                      </>

                      :

                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                      </div>

                    }

                  </Row>

                }


              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Passes Awarded </Card.Title>
                {loading1 ?
                  <ContainedLoader /> :

                  <>
                    {passesAwarded.length > 0 ?

                      <>
                        <Row>
                          <Col md={6} style={{ minHeight: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <ResponsiveContainer width='100%' height={300}>
                                <PieChart width={200} height={150} >
                                  <Pie data={passesAwarded} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={onSmallScreen ? 70 : 100}
                                    // label={renderCustomizedLabel}
                                    labelLine={false}
                                  >
                                    {passesAwarded.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                  </Pie>
                                  <Tooltip content={<CustomTooltipPass />} />
                                  <Legend />
                                </PieChart>
                              </ResponsiveContainer>
                            </div>
                          </Col>
                          <Col md={6}>

                            {/* {passesAwarded.map((item, index) => (
                              <>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                  <p style={{ margin: 0, fontWeight: 'bold' }}>{item?.name}</p>
                                </div>
                                <ul>
                                  <li key={`ticket-${index}`}>
                                    <span style={{ fontWeight: 'bold' }}>Awarded:</span>  {item?.value}
                                  </li>
                                </ul>

                              </>

                            ))} */}

                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                              <Table striped bordered hover style={tableStyles}>
                                <thead>
                                  <tr style={headerStyles}>
                                    <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                    <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                    <th style={{ textAlign: "center", ...rowCellStyles }}>No. of Passes </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {passesAwarded.map((item, index) => (
                                    <tr key={index} style={{ ...rowStyles }}>
                                      <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                      <td style={{ backgroundColor: COLORS[index % COLORS.length], fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                      <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                    </tr>
                                  ))}

                                </tbody>
                              </Table>
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <p style={{ margin: 0, fontWeight: 'bold' }}>Consolidated Totals</p>
                            </div>
                            <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                              <ul>

                                <li >
                                  <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Awarded:</span> {formatPrice(eventPassData?.awarded)} </p>
                                </li>


                              </ul>
                            </div>
                          </Col>
                        </Row>



                      </>

                      :

                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                      </div>

                    }


                  </>



                }

              </Card.Body>
            </Card>
          </Col>

          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Event Registration </Card.Title>
                {loading || loading1 ?
                  <ContainedLoader /> :

                  <>
                    {(totalRegistered > 0 || notRegistered > 0) ?

                      <>
                        <Row>
                          <Col md={6} style={{ minHeight: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <ResponsiveContainer width='100%' height={300}>
                                <PieChart >
                                  <Pie
                                    data={registrationData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%%"
                                    cy="50%"
                                    innerRadius={onSmallScreen ? 50 : 70}
                                    outerRadius={onSmallScreen ? 70 : 100}
                                    fill="#03d3fc" // Default color for the entire Pie
                                    // label={renderCustomizedLabel}
                                    labelLine={false}
                                  >
                                    {registrationData.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                  </Pie>
                                  <Tooltip content={<CustomTooltipRegistration />} />
                                  <Legend />
                                </PieChart>
                              </ResponsiveContainer>
                            </div>
                          </Col>
                          <Col md={6}>

                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <p style={{ margin: 0, fontWeight: 'bold' }}>Consolidated Totals</p>
                            </div>
                            <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                              <ul>

                                <li >
                                  <p style={{ margin: 0, color: 'green' }}><span style={{ fontWeight: 'bold' }}>Registered On Ticket:</span> {formatPrice(ticketRegistration)} </p>
                                </li>

                                <li >
                                  <p style={{ margin: 0, color: 'gold' }}><span style={{ fontWeight: 'bold' }}>Registered On Pass:</span> {formatPrice(passRegistration)} </p>
                                </li>

                                <li >
                                  <p style={{ margin: 0, color: 'gray' }}><span style={{ fontWeight: 'bold' }}>Total Registered:</span> {totalRegistered ? formatPrice(totalRegistered) : 0} </p>
                                </li>

                                <li >
                                  <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Not Registered:</span> {notRegistered ? formatPrice(notRegistered) : 0}</p>
                                </li>



                              </ul>
                            </div>

                          </Col>
                        </Row>


                      </>

                      :

                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                      </div>

                    }


                  </>



                }

              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Ticket Attendance</Card.Title>
                {loading ?
                  <ContainedLoader /> :

                  <>
                    {zoneTicketData.length > 0 ?

                      <>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                          <div style={{ width: '100%', maxWidth: '800px', overflowX: 'scroll' }}>

                            <ResponsiveContainer width='100%' minWidth={onSmallScreen ? '500px' : '100%'} height={300} >
                              <BarChart
                                width={800}
                                height={300}
                                data={zoneTicketData}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="raised" fill="#03d3fc" activeBar={<Rectangle fill="#03d3fc" stroke="#03d3fc" />} label />
                                <Bar dataKey="attended" fill="#82ca9d" activeBar={<Rectangle fill="#82ca9d" stroke="#82ca9d" />} label />
                                <Bar dataKey="missed" fill="red" activeBar={<Rectangle fill="red" stroke="red" />} label />
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </div>



                        {/* <Row className='mt-2'>
                          {zoneTicketData.map((item) => (

                            <Col md={3}>

                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>{item?.name}</span></p>
                              </div>

                              <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                <ul>

                                  <li >
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Raised:</span> {item?.raised} </p>
                                  </li>

                                  <li >
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Attended:</span> {item?.attended}</p>
                                  </li>

                                  <li >
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Missed:</span> {item?.missed}</p>
                                  </li>

                                </ul>
                              </div>
                            </Col>


                          ))}

                        </Row> */}


                        <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                          <Table striped bordered hover style={tableStyles}>
                            <thead>
                              <tr style={headerStyles}>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                <th style={{ ...rowCellStyles }}>Zone</th>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>Raised</th>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>Attended</th>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>Missed</th>
                              </tr>
                            </thead>
                            <tbody>
                              {zoneTicketData.map((item, index) => (
                                <tr key={index} style={{ ...rowStyles }}>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                  <td style={{ ...rowCellStyles, fontWeight: 'bold' }}>{item?.name}</td>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.raised)}</td>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.attended)}</td>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.missed)}</td>
                                </tr>
                              ))}

                            </tbody>
                          </Table>
                        </div>

                        <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />


                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>{'Consolidated Totals'}</span></p>
                        </div>
                        <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                          <ul>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Raised:</span> {formatPrice(eventTicketData?.raised)} </p>
                            </li>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Attended:</span> {formatPrice(eventTicketData?.attended)}</p>
                            </li>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Missed:</span> {formatPrice(eventTicketData?.missed)}</p>
                            </li>

                          </ul>
                        </div>
                      </>

                      :

                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                      </div>

                    }


                  </>



                }


              </Card.Body>
            </Card>
          </Col>
          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Pass Attendance</Card.Title>
                {loading1 ?
                  <ContainedLoader /> :

                  <>
                    {categoryPassData.length > 0 ?

                      <>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                          <div style={{ width: '100%', maxWidth: '800px', overflowX: 'scroll' }}>
                            <ResponsiveContainer width='100%' minWidth={onSmallScreen ? '500px' : '100%'} height={300} >
                              <BarChart
                                width={800}
                                height={300}
                                data={categoryPassData}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="awarded" fill="#03d3fc" activeBar={<Rectangle fill="#03d3fc" stroke="#03d3fc" />} label />
                                <Bar dataKey="attended" fill="#82ca9d" activeBar={<Rectangle fill="#82ca9d" stroke="#82ca9d" />} label />
                                <Bar dataKey="missed" fill="red" activeBar={<Rectangle fill="red" stroke="red" />} label />
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </div>



                        {/* <Row className='mt-2'>
                          {categoryPassData.map((item) => (

                            <Col md={3}>

                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>{item?.name}</span></p>
                              </div>

                              <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                <ul>

                                  <li >
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Raised:</span> {item?.awarded} </p>
                                  </li>

                                  <li >
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Attended:</span> {item?.attended}</p>
                                  </li>

                                  <li >
                                    <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Missed:</span> {item?.missed}</p>
                                  </li>

                                </ul>
                              </div>
                            </Col>


                          ))}

                        </Row> */}

                        <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                          <Table striped bordered hover style={tableStyles}>
                            <thead>
                              <tr style={headerStyles}>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                <th style={{ ...rowCellStyles }}>Zone</th>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>Awarded</th>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>Attended</th>
                                <th style={{ textAlign: "center", ...rowCellStyles }}>Missed</th>
                              </tr>
                            </thead>
                            <tbody>
                              {categoryPassData.map((item, index) => (
                                <tr key={index} style={{ ...rowStyles }}>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                  <td style={{ ...rowCellStyles, fontWeight: 'bold' }}>{item?.name}</td>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.awarded)}</td>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.attended)}</td>
                                  <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.missed)}</td>
                                </tr>
                              ))}

                            </tbody>
                          </Table>
                        </div>

                        <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />


                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>{'Consolidated Totals'}</span></p>
                        </div>

                        <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                          <ul>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Awarded:</span> {formatPrice(eventPassData?.awarded)} </p>
                            </li>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Attended:</span> {formatPrice(eventPassData?.attended)}</p>
                            </li>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Missed:</span> {formatPrice(eventPassData?.missed)}</p>
                            </li>

                          </ul>
                        </div>

                      </>

                      :

                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                      </div>

                    }

                  </>



                }


              </Card.Body>
            </Card>
          </Col>


        </Row>

        <Row className='my-2'>

          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Event Coupons | Ticket</Card.Title>
                <Row>

                  {loading2 ?
                    <ContainedLoader /> :

                    <>

                      {couponData.length > 0 ?

                        <>
                          <Col md={6}>

                            <ResponsiveContainer width='100%' height={300} >
                              <BarChart
                                width={800}
                                height={300}
                                data={couponData}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="issued" fill="#03d3fc" activeBar={<Rectangle fill="#03d3fc" stroke="#03d3fc" />} label />
                                <Bar dataKey="redeemed" fill="#82ca9d" activeBar={<Rectangle fill="#82ca9d" stroke="#82ca9d" />} label />
                              </BarChart>
                            </ResponsiveContainer>
                          </Col>
                          <Col md={6}>
                            <div>

                              {/* {couponData.map((item, index) => (
                                <>
                                  <div style={{ width: '100%', textAlign: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold' }}>{item?.name}</p>
                                  </div>
                                  <ul>
                                    <li key={`coupon-${index}`}>
                                      <span style={{ fontWeight: 'bold' }}>Issued:</span> ({item?.issued}) | {formatPrice(item?.issueCost)} UGX
                                    </li>
                                    <li key={`coupon-${index}`}>
                                      <span style={{ fontWeight: 'bold' }}>Redeemed:</span> ({item?.redeemed}) | {formatPrice(item?.redeemCost)} UGX
                                    </li>
                                  </ul>

                                </>

                              ))} */}

                              <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                <Table striped bordered hover style={tableStyles}>
                                  <thead>
                                    <tr style={headerStyles}>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                      <th style={{ ...rowCellStyles }}>Coupon</th>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>Issued</th>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>Redeemed</th>
                                      <th style={{ textAlign: "right", ...rowCellStyles }}>Issued Total Cost (UGX)</th>
                                      <th style={{ textAlign: "right", ...rowCellStyles }}> Redeemed Total Cost (UGX)</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {couponData.map((item, index) => (
                                      <tr key={index} style={{ ...rowStyles }}>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                        <td style={{ ...rowCellStyles, fontWeight: 'bold' }}>{item?.name}</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.issued)}</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.redeemed)}</td>
                                        <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.issueCost)}</td>
                                        <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.redeemCost)}</td>

                                      </tr>
                                    ))}

                                  </tbody>
                                </Table>
                              </div>

                              <>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                  <p style={{ margin: 0, fontWeight: 'bold' }}>{'Consolidated Coupon Amounts'}</p>
                                </div>
                                <ul>
                                  <li >
                                    <span style={{ fontWeight: 'bold' }}>Issued:</span> ({eventCouponData?.issued}) | {formatPrice(eventCouponData?.issueCost)} UGX
                                  </li>
                                  <li >
                                    <span style={{ fontWeight: 'bold' }}>Redeemed:</span> ({eventCouponData?.redeemed}) | {formatPrice(eventCouponData?.redeemCost)} UGX
                                  </li>
                                </ul>

                              </>

                            </div>
                          </Col>
                        </>

                        :

                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                        </div>

                      }



                    </>



                  }

                </Row>

              </Card.Body>
            </Card>
          </Col>


        </Row>

        <Row className='my-2'>

          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Event Coupons | Pass</Card.Title>
                <Row>
                  {loading4 ?
                    <ContainedLoader /> :

                    <>

                      {couponDataPass.length > 0 ?

                        <>
                          <Col md={6}>
                            <ResponsiveContainer width='100%' height={300} >
                              <BarChart
                                width={800}
                                height={300}
                                data={couponDataPass}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="issued" fill="#03d3fc" activeBar={<Rectangle fill="#03d3fc" stroke="#03d3fc" />} label />
                                <Bar dataKey="redeemed" fill="#82ca9d" activeBar={<Rectangle fill="#82ca9d" stroke="#82ca9d" />} label />
                              </BarChart>
                            </ResponsiveContainer>
                          </Col>
                          <Col md={6}>
                            <div>

                              {/* {couponDataPass.map((item, index) => (
                                <>
                                  <div style={{ width: '100%', textAlign: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold' }}>{item?.name}</p>
                                  </div>
                                  <ul>
                                    <li key={`coupon-${index}`}>
                                      <span style={{ fontWeight: 'bold' }}>Issued:</span> ({item?.issued}) | {formatPrice(item?.issueCost)} UGX
                                    </li>
                                    <li key={`coupon-${index}`}>
                                      <span style={{ fontWeight: 'bold' }}>Redeemed:</span> ({item?.redeemed}) | {formatPrice(item?.redeemCost)} UGX
                                    </li>
                                  </ul>

                                </>

                              ))} */}

                              <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                <Table striped bordered hover style={tableStyles}>
                                  <thead>
                                    <tr style={headerStyles}>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                      <th style={{ ...rowCellStyles }}>Coupon</th>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>Issued</th>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>Redeemed</th>
                                      <th style={{ textAlign: "right", ...rowCellStyles }}>Issued Total Cost (UGX)</th>
                                      <th style={{ textAlign: "right", ...rowCellStyles }}> Redeemed Total Cost (UGX)</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {couponDataPass.map((item, index) => (
                                      <tr key={index} style={{ ...rowStyles }}>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                        <td style={{ ...rowCellStyles, fontWeight: 'bold' }}>{item?.name}</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.issued)}</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.redeemed)}</td>
                                        <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.issueCost)}</td>
                                        <td style={{ textAlign: "right", ...rowCellStyles }}>{formatPrice(item?.redeemCost)}</td>

                                      </tr>
                                    ))}

                                  </tbody>
                                </Table>
                              </div>

                              <>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                  <p style={{ margin: 0, fontWeight: 'bold' }}>{'Consolidated Coupon Amounts'}</p>
                                </div>
                                <ul>
                                  <li >
                                    <span style={{ fontWeight: 'bold' }}>Issued:</span> ({formatPrice(eventCouponDataPass?.issued)}) | {formatPrice(eventCouponDataPass?.issueCost)} UGX
                                  </li>
                                  <li >
                                    <span style={{ fontWeight: 'bold' }}>Redeemed:</span> ({formatPrice(eventCouponDataPass?.redeemed)}) | {formatPrice(eventCouponDataPass?.redeemCost)} UGX
                                  </li>
                                </ul>

                              </>

                            </div>
                          </Col>

                        </>

                        :

                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                        </div>

                      }


                    </>



                  }

                </Row>

              </Card.Body>
            </Card>
          </Col>


        </Row>
        <Row className='my-2'>
          <Col className='mt-2 mb-2' md={12}>
            <Card style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title className='text-muted' style={{ fontSize: '12px' }}>Pass Checker Ticket Sales | POS</Card.Title>
                {loading5 ?
                  <ContainedLoader /> :

                  <>
                    {eventPassCheckerData?.total > 0 ?

                      <>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                          <div style={{ width: '100%', maxWidth: '800px', overflowX: 'scroll' }}>

                            <Col md={12} style={{ minHeight: '100%' }}  >

                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <ResponsiveContainer width='100%' height={300}>
                                  <PieChart width={200} height={150}>
                                    <Pie data={eventPassCheckerPieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={onSmallScreen ? 70 : 100}

                                      labelLine={false}
                                    >
                                      {eventPassCheckerPieData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                      ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltipPassCheckers />} />
                                    <Legend />
                                  </PieChart>

                                </ResponsiveContainer>
                              </div>

                            </Col>
                          </div>
                        </div>



                        <Row>
                          {eventPassCheckerData?.passCheckerData?.map((checker) => (

                            <Col md={4}>
                              <div className='mt-2 table-responsive ' >
                                <Table striped bordered hover style={tableStyles}>
                                  <thead>
                                    <tr style={headerStyles}>
                                      <th colSpan={4} style={{ textAlign: "center", ...rowCellStyles }}>{checker?.name}</th>
                                    </tr>
                                    <tr style={headerStyles}>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                      <th style={{ ...rowCellStyles }}>Zone</th>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>Sold</th>
                                      <th style={{ textAlign: "center", ...rowCellStyles }}>Amount (UGX)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {checker?.salesByZone?.map((item, index) => (
                                      <tr key={index} style={{ ...rowStyles }}>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                        <td style={{ ...rowCellStyles, fontWeight: 'bold' }}>{item?.zone}</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.sold)}</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.amountCollected)}</td>
                                      </tr>
                                    ))}
                                    <tr key={'total'} style={{ ...rowStyles }}>
                                        
                                        <td colSpan={2} style={{ ...rowCellStyles, fontWeight: 'bold',textAlign: "center" }}>TOTALS</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(checker?.sales)}</td>
                                        <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(checker?.amount)}</td>
                                      </tr>

                                  </tbody>
                                </Table>
                              </div>
                            </Col>

                          ))}

                        </Row>


                        <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />


                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>{'Consolidated Totals'}</span></p>
                        </div>
                        <div className='mt-2' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                          <ul>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Pass Checkers:</span> {formatPrice(eventPassCheckerData?.total)} </p>
                            </li>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Active Pass Checkers:</span> {formatPrice(eventPassCheckerData?.active)}</p>
                            </li>

                            <li >
                              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }}>Total Inactive Pass Checkers:</span> {formatPrice(eventPassCheckerData?.inactive)}</p>
                            </li>

                          </ul>
                        </div>
                      </>

                      :

                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p className='text-muted' style={{ margin: 0 }}>{'No data'}</p>
                      </div>

                    }


                  </>



                }


              </Card.Body>
            </Card>
          </Col>
        </Row>

      </div>
    )
  }
}

export default EventStatistics;
