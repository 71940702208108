import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import { Container } from 'react-bootstrap';
import dashLogo from './images/IMG-20220424-WA0006.jpg'
import { Image } from 'react-bootstrap';
import Cookies from 'js-cookie';



const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 6px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavImage = styled.div`
  margin-left: 15px;
  font-size: 6px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #fff;
  width: 20%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 50ms;
  z-index: 10;

  

  @media screen and (max-width: 800px) {
    width: 100%; // Make it full-width on smaller screens
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 0.3s;
  }
  
`;

const SidebarWrap = styled.div`
  width: 100%;
  
`;

const Sidebar = ({ open, showSidebar }) => {
  const [sidebar, setSidebar] = useState(true);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      <IconContext.Provider value={{ color: '#000000', }}>

        <SidebarNav sidebar={open}>

          {screenWidth <= 800 && (
            <AiIcons.AiOutlineClose onClick={showSidebar} />
          )}
          <SidebarWrap>
            <NavImage >
              <img src={dashLogo} alt="sidebar image" style={{ width: "150px" }} />
            </NavImage>

            {SidebarData.map((item, index) => {
              return <SubMenu sidebar={showSidebar} item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </div>
  );
};

export default Sidebar;
