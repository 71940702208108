import { Navigate } from "react-router-dom";



const ProtectedRoute = ({
    loggedin,
    redirectPath = '/login',
    children,
  }) => {
    if (loggedin) {
        return children; 
    }
    return <Navigate to={redirectPath} replace />;
    
  };

export default ProtectedRoute;